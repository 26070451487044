var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item py-2"},[_c('router-link',{attrs:{"to":_vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8'
        ? {
            name: 'Product',
            params: { productSlug: _vm.item.product.slug }
          }
        : ''}},[_c('v-row',{staticClass:"pb-4",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-row align-center",attrs:{"cols":"9","sm":"5"}},[_c('img',{style:({ width: '60px', height: '60px' }),attrs:{"src":_vm.item.product.mediaURL,"alt":'Immagine prodotto'}}),_c('div',{staticClass:"cart-item-content"},[_c('div',{staticClass:"descripion"},[_c('div',{staticClass:"text-wrap font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.product.description)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.item.product.shortDescr)+" ")]),_c('div',{staticClass:"cart-item-info"},[_c('em',[_vm._v(_vm._s(_vm.item.cartItemInfo.size))])])])])]),(_vm.full)?_c('v-col',{attrs:{"cols":"3","sm":"2"}},[_c('ProductPrice',{attrs:{"product":_vm.item.product,"isPackage":_vm.item.cartItemInfo.is_package}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"8","sm":_vm.full ? 3 : 5}},[_c('div',{class:'actions d-flex align-center'},[_c('div',{staticClass:"qty-wrap rounded-pill",class:{ 'not-empty': _vm.quantity > 0 }},[(!_vm.showQntyInput)?[(_vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8')?_c('a',{staticClass:"minus",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.minus(true, { infos: _vm.item.cartItemInfo })},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_vm._e(),_c('div',{directives:[{name:"long-press",rawName:"v-long-press",value:(300),expression:"300"}],staticClass:"val-cnt",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"dblclick":function($event){_vm.item.cartItemInfo.is_package == 'true'
                    ? _vm.toggleShowInput()
                    : () => {}},"long-press-start":function($event){_vm.item.cartItemInfo.is_package == 'true'
                    ? _vm.toggleShowInput()
                    : () => {}},"mousedown":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))]),(_vm.item.cartItemInfo.is_package == 'true')?_c('span',{staticClass:"package small"},[_vm._v(" "+_vm._s(_vm.packageCount)+" ")]):_vm._e()]),(_vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8')?_c('a',{staticClass:"plus",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.plus(true, { infos: _vm.item.cartItemInfo })},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]:[_c('a',{staticClass:"close minus",attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleShowInput(false)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-tooltip',{attrs:{"bottom":"","open-on-hover":false,"open-on-click":true,"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],ref:"manualInput",staticClass:"manual-input",attrs:{"hide-details":"","height":"52","autofocus":"","outlined":"","dense":"","type":"number"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.toggleShowInput(true, {
                        infos: { is_package: _vm.item.cartItemInfo.is_package }
                      })},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.toggleShowInput(false)}],"mousedown":function($event){$event.stopPropagation();}},model:{value:(_vm.qntyInput),callback:function ($$v) {_vm.qntyInput=$$v},expression:"qntyInput"}},'v-text-field',attrs,false),on))]}}])},[(!_vm.modulus(_vm.qntyInput))?_c('span',{staticClass:"d-block qntity-tooltip",domProps:{"innerHTML":_vm._s(
                    _vm.$t('product.quantity.confirmQntity', {
                      qntyInput: this.qntyInput
                    })
                  )}}):_vm._e(),(
                    _vm.modulus(_vm.qntyInput) &&
                      parseInt(_vm.qntyInput) <=
                        parseInt(this.product.productInfos.QTY_MULT)
                  )?_c('span',{staticClass:"d-block qntity-tooltip",domProps:{"innerHTML":_vm._s(
                    _vm.$t('product.quantity.editQntityMin', {
                      lowerInput: this.lowerInput
                    })
                  )}}):_vm._e(),(
                    _vm.modulus(_vm.qntyInput) &&
                      parseInt(_vm.qntyInput) >
                        parseInt(this.product.productInfos.QTY_MULT)
                  )?_c('span',{staticClass:"qntity-tooltip",domProps:{"innerHTML":_vm._s(
                    _vm.$t('product.quantity.editQntityMinMax', {
                      lowerInput: this.lowerInput,
                      higherInput: this.higherInput
                    })
                  )}}):_vm._e()]),(_vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8')?_c('a',{staticClass:"check plus",attrs:{"role":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleShowInput(true, {
                    infos: { is_package: _vm.item.cartItemInfo.is_package }
                  })},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]],2)])]),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"4","sm":"2"}},[_c('div',{staticClass:"gross-total price"},[_vm._v(" "+_vm._s(_vm.$n(_vm.item.grossTotal, "currency"))+" ")]),_c('v-btn',{staticClass:"remove-cart-item ml-4",attrs:{"icon":"","aria-label":"Rimuovi dal carrello"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1),(_vm.full && _vm.item.product.productInfos.PRODUCT_NATURE_ID !== '8')?_c('v-row',{staticClass:"alternatives mt-2",attrs:{"no-gutters":"","align-content":"center"}},[_c('v-textarea',{attrs:{"outlined":"","no-resize":"","auto-grow":"","maxlength":1000,"label":_vm.$t('cart.notes.itemNote'),"rows":"1","dense":"","depressed":""},on:{"blur":function($event){return _vm.setCartItemInfo({
          items: _vm.item,
          name: 'user_notes',
          value: _vm.tempUserNotes
        })}},model:{value:(_vm.userNotes),callback:function ($$v) {_vm.userNotes=$$v},expression:"userNotes"}})],1):_vm._e(),_c('v-divider'),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('div',{staticClass:"vuedl-layout__closeBtn",on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" × ")]),_c('v-card',[_c('v-card-text',{staticClass:"body-1 pt-8"},[_vm._v(" "+_vm._s(_vm.$t("message.remove", { name: this.product.name, quantity: this.quantity, unit: this.unit }))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"green darken-1","text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","depressed":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.doRemove.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" Si ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }