var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"menu",staticClass:"masonry-category-menu px-4"},[_c('div',{staticClass:"d-flex masonry-row"},[_c('div',{staticClass:"all-products",attrs:{"flat":""}},[_c('v-list',{attrs:{"flat":"","height":_vm.listHeight}},[_c('v-list-item-group',{model:{value:(_vm.selectedCategoryFirstLevel),callback:function ($$v) {_vm.selectedCategoryFirstLevel=$$v},expression:"selectedCategoryFirstLevel"}},_vm._l((_vm.categories),function(category){return _c('v-list-item',{key:category.categoryId,staticClass:"px-1",attrs:{"dense":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.selectCategory(category)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-3 category-icon"},[_c('img',{attrs:{"src":category.metaData
                        ? category.metaData.category_info.ICON
                        : ''}})]),_c('router-link',{staticClass:"menu-link",attrs:{"to":{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.selectCategory(category)}}},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("mdi-chevron-down")])],1)],1)],1)}),1)],1)],1),(_vm.categorySelected)?_c('div',{staticClass:"category2 px-4",attrs:{"flat":""}},[_c('v-list',{staticClass:"text-body-1"},_vm._l((_vm.categorySelected.categories),function(category2){return _c('v-list-item',{key:category2.categoryId,attrs:{"to":{
            name: 'Category',
            params: { pathMatch: category2.slug }
          },"link":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(" "+_vm._s(category2.name)+" ")])}),1)],1):_vm._e(),(_vm.categoryOpened)?_c('category-block',{staticClass:"category-block category-block-menu mt-3",attrs:{"target":_vm.categoryOpened,"position":"menu","container":false}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }